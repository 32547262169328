import { gql } from "@apollo/client";
import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";

import {
  CategoryFragment,
  StatusFragment,
} from "../components/app/ProjectLabels.generated.js";
import { useHexSelector } from "../hex-version-multiplayer/state-hooks/hexStateHooks.js";
import { ORG_ID } from "../orgs.js";
import { HexMP } from "../redux/slices/hexMPSlice.js";

import { useGetCategoriesAndStatusesQuery } from "./useProjectLabelsForHex.generated.js";

export type MinimalCategory = Omit<CategoryFragment, "__typename">;
export type MinimalStatus = Omit<StatusFragment, "__typename">;

type StatusForProject = MinimalStatus & {
  enforcesReview: boolean;
};

gql`
  query GetCategoriesAndStatuses($orgId: OrgId!) {
    orgById(orgId: $orgId) {
      id
      categories {
        ...CategoryFragment
      }
      statuses {
        ...StatusFragment
        enforcesReview
      }
    }
  }
`;

interface ProjectLabels {
  appliedCategories: MinimalCategory[];
  appliedStatus: StatusForProject | null;
  allOrgCategories: readonly MinimalCategory[];
  allOrgStatuses: readonly StatusForProject[];
}

const hexCategoryIdsSelector = createSelector(
  (hex: HexMP) => hex,
  (hex: HexMP) => hex.categories.map((c) => c.id),
);

export function useProjectLabelsForHex(): ProjectLabels {
  const { data, error, loading } = useGetCategoriesAndStatusesQuery({
    variables: { orgId: ORG_ID },
  });

  const { appliedCategoryIds, appliedStatusId } = useHexSelector({
    selector: (hex: HexMP) => ({
      appliedStatusId: hex.statusId,
      appliedCategoryIds: hexCategoryIdsSelector(hex),
    }),
    equalityFn: shallowEqual,
  });

  return useMemo(() => {
    if (!data || error || loading) {
      return {
        appliedCategories: [],
        appliedStatus: null,
        allOrgCategories: [],
        allOrgStatuses: [],
      };
    }

    const allOrgCategories = data?.orgById?.categories;
    const allOrgStatuses = data?.orgById.statuses;

    const appliedCategories = allOrgCategories?.filter((c) =>
      appliedCategoryIds.includes(c.id),
    );
    const appliedStatus = allOrgStatuses?.find((s) => s.id === appliedStatusId);

    return {
      appliedCategories: appliedCategories ?? [],
      appliedStatus: appliedStatus ?? null,
      allOrgCategories,
      allOrgStatuses,
    };
  }, [appliedCategoryIds, appliedStatusId, data, error, loading]);
}
